import React, { useState, useEffect,useRef } from "react";
import { FaUsers, FaBook, FaUser, FaTypo3, FaTable } from 'react-icons/fa';
import BASE_URL from "../constant";
import axios from "axios";
import { getDatabase, ref, push, set } from "firebase/database";
import { db } from "../firebase";
import { toast } from "react-toastify";


const Dashboard = () => {
  
  const [totalVisitors,settotalVisitor] = useState(0);
  const [totalUsers,settotalUsers] = useState(0);
  const [totalBooks,settotalBooks] =useState(0);
  const [users,setUsers] = useState( [
   
  ]);
  const messageRef = useRef(null); 
  const titleRef = useRef(null); 

  useEffect(() => {
    fetchUsers();
    fetchBooks();
    fetchCategories();
}, []);

const handleCreateNotification = async () => {
  const message = messageRef.current.value;
  const title= titleRef.current.value;
  

  try {
    const notifyData = {
      notify: true,
      title:title,
      message: message,
      timestamp: Date.now()
    };

    
    await Promise.all(
      users.map((user) =>
        addNotification(user.fb_id,notifyData)
      )
    );

    setIsModalOpen(false); 
  
    toast("Notification sent to all users.");
  } catch (error) {
    console.error("Error sending notifications:", error);
  }
};

const  addNotification =   async (userId, notificationData) =>{

  const userNotifyRef = ref(db, `notify/${userId}`);

  
  const newNotificationRef = push(userNotifyRef);
  
 
  try {
    await set(newNotificationRef, { ...notificationData, notify: true });
    console.log("Notification added successfully with notify flag set to true.");
  } catch (error) {
    console.error("Error adding notification:", error);
  }
}

const fetchBooks = async () => {
    try {
        let url = `${BASE_URL}/api_book.php/books`;
        
        const response = await axios.get(url);
        const bookData = response.data;
       
        if (Array.isArray(bookData)) {
          settotalBooks(bookData.length);
           
        } else {
            console.error('Expected an array of books');
        }
    } catch (error) {
        console.error('Error fetching books:', error);
    }
};
const fetchCategories = async () => {
  try {
      let url = `${BASE_URL}/api_categories.php`;
     
      const response = await axios.get(url);
      const bookData = response.data;
     
      if (Array.isArray(bookData)) {
        settotalVisitor(bookData.length);
         
      } else {
          console.error('Expected an array of Categories');
      }
  } catch (error) {
      console.error('Error fetching books:', error);
  }
};

const fetchUsers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api_users.php`);
        // console.log(response.data);
        setUsers(response.data);
        settotalUsers(response.data.length);
    } catch (error) {
        console.error("Error fetching books:", error);
    }
};
  const books = [
   
   
  ];

  const totalSales = books.reduce((acc, book) => acc + book.sales, 0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const CustomModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          
          <div>{children}</div>
        </div>
      </div>
    );
  };
  return (
    
    <div className="container mx-auto">
    <div className="relative w-full">
  <div className="flex justify-end">
    {/* <button
      onClick={() => setIsModalOpen(true)}
      className="px-4 py-2 bg-red-900 text-white rounded-full absolute top-0 right-0"
    >
      Create Notification for All Users
    </button> */}
  </div>
  <br></br>
</div>

      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl mb-4">Send Notification to All Users</h2>
        <h3 className="text-2l mb-2">Title:</h3>
        <textarea
          className="w-half  border rounded"
          id="title" name="title" 
          placeholder="Enter notification title"
          ref={titleRef} 
        ></textarea>
         <h3 className="text-2l mb-2">Message:</h3>
        <textarea
          className="w-full p-2 border rounded"
          id="message" name="message" 
          placeholder="Enter notification message"
          ref={messageRef} 
        ></textarea>
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsModalOpen(false)}
            className="px-4 py-2 bg-red-400 text-white rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={()=>{
              handleCreateNotification();}}
            className="px-4 py-2 bg-red-800 text-white rounded"
          >
            Send Notification
          </button>
        </div>
      </CustomModal>
      <div className="grid grid-cols-3 mt-8 gap-4">
        {/* Total Visitors */}
        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center space-x-4">
          <FaTable className="text-4xl text-orange-300" />
          <div>
            <p className="text-2xl font-semibold">{totalVisitors}</p>
            <p className="text-sm text-gray-500">Total Categories</p>
          </div>
        </div>

        {/* Total Users */}
        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center space-x-4">
          <FaUser className="text-4xl text-green-400	" />
          <div>
            <p className="text-2xl font-semibold">{totalUsers}</p>
            <p className="text-sm text-gray-500">Total Users</p>
          </div>
        </div>

        {/* Total Books */}
        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center space-x-4">
          <FaBook className="text-4xl " />
          <div>
            <p className="text-2xl font-semibold">{totalBooks}</p>
            <p className="text-sm text-gray-500">Total Books</p>
          </div>
        </div>
      </div>
     
    </div>
  );
};

const UserTable = ({ users }) => {
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border px-4 py-2">User Name</th>
            <th className="border px-4 py-2">Email</th>
            <th className="border px-4 py-2">Contact Number</th>
            <th className="border px-4 py-2">Address</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td className="border px-4 py-2">{user.username}</td>
              <td className="border px-4 py-2">{user.email}</td>
              <td className="border px-4 py-2">{user.contact}</td>
              <td className="border px-4 py-2">{user.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;
