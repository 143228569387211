// src/pages/ContactUsList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../constant";
import { Dropdown, DropdownButton, Modal, Button } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import "./custom.css";

const ContactUsList = () => {
  const [modalShow, setModalShow] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [messageLength, setMessageLength] = useState(50); // Change message length as needed
  const [filterType, setFilterType] = useState("all");
  const [selectedMessage, setSelectedMessage] = useState("");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/chat_simple.php/`)
      .then((response) => {
        const data = response.data;
        setTableData(data || []);
        setFilteredTableData(data || []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

 

  const handleRemoveClick = (id) => {
    axios
      .delete(`${BASE_URL}/chat_simple.php`, {
        data: {
          id: id,
        },
      })
      .then((response) => {
        alert("Chat deleted successfully:", response.data.message);
      })
      .catch((error) => {
        alert("Error deleting chat:", error.response.data.message);
      });

    setTableData((prevData) => prevData.filter((member) => member.id !== id));
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    let filteredData;
  
    switch (type) {
      case "latest":
        // Sort by created_at in descending order for the latest entries first
        filteredData = [...tableData].sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        break;
  
      case "oldest":
        // Sort by created_at in ascending order for the oldest entries first
        filteredData = [...tableData].sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        break;
  
      case "today":
        // Filter for entries created today
        const today = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
        filteredData = tableData.filter(
          (item) => item.date.split(" ")[0] === today
        );
        break;
  
      default:
        // 'all' case: show all items without filtering or sorting
        filteredData = [...tableData];
        break;
    }
  
    setFilteredTableData(filteredData); // Update state with the filtered data
  };
  
  

  const handleShowMore = (message) => {
    setSelectedMessage(message);
    setModalShow(true);
  };

  const CustomDropdown = ({ title, options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(title);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const handleSelect = (option) => {
      setSelectedOption(option.label);
      onSelect(option.value);
      setIsOpen(false);
    };

    return (
      <div className="custom-dropdown">
        <button className="dropdown-button" onClick={handleToggle}>
          {selectedOption} ▾
        </button>
        {isOpen && (
          <ul className="dropdown-menu">
            {options.map((option, index) => (
              <li
                key={index}
                className="dropdown-item"
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };


  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Latest", value: "latest" },
    { label: "Oldest", value: "oldest" },
    { label: "Today", value: "today" },
    // Add more options as needed
  ];

  return (
    <>
      <div className="container mx-auto py-8">
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">Contact Us List </h2>
          <div className="bg-[#6D3815] drop-shadow-xl hover:bg-black text-white px-4 py-2 rounded-md flex items-center focus:outline-none mr-2">
            <CustomDropdown
              title={`Filter: ${filterType}`}
              options={filterOptions}
              onSelect={handleFilterChange}
            />
          </div>
        </div>

        {/* </div> */}
        <div className="overflow-y-auto max-h-[600px]">
          <table className="table-auto  w-full rounded-md border-collapse border border-gray-400">
            <thead className="bg-[#fde5c3c4]  text-left">
              <tr>
                <th className="px-4 py-2  border border-gray-400">
                  Customer Name
                </th>
                <th className="px-4 py-2 border border-gray-400">
                  Customer Email
                </th>
                <th className="px-4 py-2 border border-gray-400">Message</th>
                <th className="px-4 py-2 border border-gray-400">Actions</th>
              </tr>
            </thead>

            <tbody>
              {filteredTableData.map((customer) => (
                <tr key={customer.id}>
                  <td className="px-4 py-2 border border-gray-400">
                    {customer.client_name}
                  </td>
                  <td className="px-4 py-2 border border-gray-400">
                    {customer.client_email}
                  </td>
                  <td className="px-4 py-2 border border-gray-400">
                    <p>
                      {customer.message.length > messageLength
                        ? `${customer.message.slice(0, messageLength)}...`
                        : customer.message}
                    </p>
                    {customer.message.length > messageLength && (
                      <button onClick={() => handleShowMore(customer.message)}>
                        Show More
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-2 border border-gray-400">
                    <Button
                      variant="danger"
                      style={{ marginLeft: "4px" }}
                      onClick={() => handleRemoveClick(customer.id)}
                    >
                      <BsTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal for editing name */}
      {modalShow && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex w-full sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Message Details
                    </h3>
                    <div className="mt-2">
                      <div
                        className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm overflow-y-auto"
                        style={{ height: "150px" }}
                      >
                        {selectedMessage ?? "No message selected"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={() => setModalShow(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUsList;
