import React, { useState, useEffect } from "react";
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "../Pages/LoadingPopup";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Library = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchBooks();
        fetchTopics();
    }, []);

    const fetchBooks = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_home.php/library_books`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    };

    const fetchTopics = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_home.php/topics`);
            setTopics(response.data);
        } catch (error) {
            console.error('Error fetching topics:', error);
        }
    };
    const addBook = async (book) => {
        try {
            setIsLoading(true);
            console.log(book);
            const formData = new FormData();
      formData.append('topic_id', book.topic_id);
      formData.append('book_link', book.book_link);
      formData.append('book_title', book.book_title);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
            const response = await axios.post(`${BASE_URL}/api_home.php/library_books`, formData,config);
            console.log(response.data);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error('Error adding book:', error);
            throw error;
        }
    };
    
    // Function to update a book
    const updateBook = async (book) => {
        try {
            setIsLoading(true);
            console.log(book);
            const params = new URLSearchParams();
            params.append('id', book.id);
            params.append('topic_id', book.topic_id);
            params.append('book_title', book.book_title);
            params.append('book_link', book.book_link);
    
            const response = await axios.put(`${BASE_URL}/api_home.php/library_books`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log(response.data);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error('Error updating book:', error);
            throw error;
        }
    };
    const updateBookids = async (orignal_id,replace_id) => {
        try {
            setIsLoading(true);
            const originalIdsArray = convertToArray(orignal_id);
            const replaceIdsArray = convertToArray(replace_id);
            const params = new URLSearchParams();
           if (originalIdsArray.length !== replaceIdsArray.length) {
                throw new Error('Mismatched ids count');
            }

            // Convert arrays to JSON strings
            params.append('original_ids', JSON.stringify(originalIdsArray));
            params.append('replace_ids', JSON.stringify(replaceIdsArray));
           
            console.log(params);
            const response = await axios.put(`${BASE_URL}/api_home.php/library_books`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log(response.data);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error('Error updating book:', error);
            throw error;
        }
    };
    function convertToArray(input) {
        if (Array.isArray(input)) {
            return input; // Return as is if already an array
        } else if (typeof input === 'string') {
            // Remove unwanted characters and split by commas
            return input.replace(/[\[\]\"]/g, '').split(',').map(Number);
        }
        return []; // Return empty array if input is neither a string nor an array
    }
    
    // Function to delete a book
    const deleteBook = async (id) => {
        try {
            const params = new URLSearchParams();
            params.append('id', id);
    
            const response = await axios.delete(`${BASE_URL}/api_home.php/library_books`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: params
            });
    
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error('Error deleting book:', error);
            throw error;
        }
    };
    
    
    // Function to add a new topic
    const addTopic = async (topic) => {
        try {
            setIsLoading(true);
            const response = await axios.post(`${BASE_URL}/api_home.php/topics`, topic);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error('Error adding topic:', error);
            throw error;
        }
    };
    
    // Function to update a topic
    const updateTopic = async (topic) => {
        try {
            setIsLoading(true);
            console.log(editedTopic);
            const params = new URLSearchParams();
             params.append('id', editedTopic.id);
             params.append('name', editedTopic.name);
           
    
            const response = await axios.put(`${BASE_URL}/api_home.php/topics`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log(response.data);
            setIsTopicModalOpen(false);
            setEditedTopic({});
            setIsLoading(false);
            return response.data;
            // const response = await axios.put(`${BASE_URL}/api_home.php/topics`, topic);
            // return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error('Error updating topic:', error);
            throw error;
        }
    };
    
    // Function to delete a topic
    const deleteTopic = async (id) => {
        try {
            const params = new URLSearchParams();
            params.append('id', id);
            const response = await axios.delete(`${BASE_URL}/api_home.php/topics`, {   
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params });
            return response.data;
        } catch (error) {
            console.error('Error deleting topic:', error);
            throw error;
        }
    };
    const [data, setData] = useState([]);
    const [topics, setTopics] = useState([]);
   
    const [editedData, setEditedData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModaltopicOpen, setIsTopicModalOpen] = useState(false);
    const [newBookData, setNewBookData] = useState({
        name: "",
        topic: "",
        link: ""
    });
    const [editedTopic, setEditedTopic] = useState({});

   
    const [newTopic, setNewTopic] = useState("");
    const [filterTopic, setFilterTopic] = useState("");

    const handleDelete = async (id) => {
        try {
            await deleteBook(id);
            setData(data.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error deleting book:', error);
        }
    };

    const handleEdit = (item) => {
        setNewBookData({
            name: item.book_title,
            topic: item.topic_id,
            link: item.book_link
        });
        setEditedData(item);
        setIsModalOpen(true);
    };

    const handleSave = async () => {
        try {
            if (editedData.id) {
                await updateBook({
                    id:editedData.id,
                    topic_id: newBookData.topic, book_title: newBookData.name, book_link: newBookData.link
                });
                setData(data.map(item => (item.id === editedData.id ? {
                    topic_id: newBookData.topic, book_title: newBookData.name, book_link: newBookData.link
                } : item)));
            } else {
                const newBook = { topic_id: newBookData.topic, book_title: newBookData.name, book_link: newBookData.link };
                const response = await addBook(newBook);
                newBook.id = response.id; // Assuming the response includes the new book's ID
                setData([...data, newBook]);
            }
            setIsModalOpen(false);
            setEditedData({});
            setNewBookData({
                name: "",
                topic: "",
                link: ""
            });
        } catch (error) {
            console.error('Error saving book:', error);
        }
    };

    const handleNewBookChange = (e) => {
        const { name, value } = e.target;
        setNewBookData({ ...newBookData, [name]: value });
    };

    const handleNewTopicChange = (e) => {
        const  value  = e.target;
        setNewBookData({ ...newBookData,  value });
    };

    const handleFilterChange = (e) => {
        setFilterTopic(e.target.value);
    };

    const handleTopicDelete = async (topic) => {
        try {
            await deleteTopic(topic.id);
            setTopics(topics.filter(t => t.id !== topic.id));
            setData(data.filter(item => item.topic !== topic.name));
        } catch (error) {
            console.error('Error deleting topic:', error);
        }
    };

    // const handleEditTopic = (topic) => {
    //     setEditedTopic(topic);
    //     setIsTopicModalOpen(true);
    //     console.log(`Editing topic: ${topic.name}`);
    // };
    function generateUniqueId() {
        return `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    }
    const handleTopicEdit = (topic) => {

        setEditedTopic(topic);
        setIsTopicModalOpen(true);
        console.log(`Editing topic: ${topic.name}`);}

    const handleAddTopic = async () => {
        if (newTopic.trim() !== "" && !topics.find(t => t.name === newTopic)) {
            try {
                const response = await addTopic({ name: newTopic });
                setTopics([...topics, { id: response.id, name: newTopic }]);
                setNewTopic("");
            } catch (error) {
                console.error('Error adding topic:', error);
            }
        }
    };
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
console.log(items);
        // setData(items);
       let orignal= data;
        //
        let original_ids=[];
        let replace_ids=[];
    
        try {
            // Update backend for each book's new order
            for (let i = 0; i < items.length; i++) {
                const book = items[i];
                
               console.log({index:i,replaced_id:book.id,orignal_id:orignal[i]}  ) ;
               original_ids.push(data[i].id); // ID from the original order
               replace_ids.push(book.id);     // ID from the new order
       
               console.log({ index: i, original_id: data[i].id, replaced_id: book.id });
                // await updateBookOrder(book.id, i);
            }
            updateBookids(original_ids,replace_ids);
            console.log({  original_ids: original_ids, replaced_ids:replace_ids });
           setData(items);
           setTimeout(() => {
            fetchBooks();
        }, 500);
            // Update state with reordered items
            
        } catch (error) {
            setData(items);
            console.error('Error updating book order:', error);
            // Handle error if needed
        }
        
    };

const filteredBooks = filterTopic
    ? data.filter(book => book.topic_name === filterTopic)
    : data;
    return (
        <div className="p-10">
            <h1 className="text-3xl my-6">Homepage Library Books</h1>
            <button onClick={() => {setEditedData({});
                setIsModalOpen(true);}} className="bg-[#6D3815] hover:bg-black text-white px-4 py-2 rounded-md mb-4">Add Book to Library</button>
            <div className="mb-4">
                <select 
                    value={filterTopic} 
                    onChange={handleFilterChange} 
                    className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
                >
                    <option value="">Filter by Topic</option>
                    {topics.map(topic => (
                        <option key={topic.id} value={topic.name}>{topic.name}</option>
                    ))}
                </select>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={generateUniqueId()}>
        {(provided) => (
          <div
            className="droppable-area"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <ul>
              {filteredBooks.map((item, index) => (
                <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="bg-white shadow-md p-4 mb-4 rounded-md flex justify-between items-center"
                    >
                      <div>
                        <h3 className="text-xl font-semibold">{item.book_title}</h3>
                        <p>Topic: {item.topic_name}</p>
                        <p>Link: <a href={item.book_link} target="_blank" rel="noopener noreferrer">{item.book_link}</a></p>
                      </div>
                      <div className="flex">
                        <button
                          onClick={() => handleEdit(item)}
                          className="bg-yellow-500 hover:bg-yellow-600 text-white px-2 py-1 rounded-md mr-2"
                        >
                          <BsPencilSquare />
                        </button>
                        <button
                          onClick={() => handleDelete(item.id)}
                          className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-md"
                        >
                          <BsTrash />
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
{/* 
 <DragDropContext onDragEnd={onDragEnd}>
 <Droppable droppableId="filteredBooks">
                    {(provided) => (
                        <div
                            className="droppable-area"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <ul>
                                {filteredBooks.map((item, index) => (
                                        <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                            {(provided) => (
                                                <li
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    className="bg-white shadow-md p-4 mb-4 rounded-md flex justify-between items-center"
                                                >
                                                    <div>
                                                        <h3 className="text-xl font-semibold">{item.book_title}</h3>
                                                        <p>Topic: {item.topic_name}</p>
                                                        <p>Link: <a href={item.book_link} target="_blank" rel="noopener noreferrer">{item.book_link}</a></p>
                                                    </div>
                                                    <div className="flex">
                                                        <button
                                                            onClick={() => handleEdit(item)}
                                                            className="bg-yellow-500 hover:bg-yellow-600 text-white px-2 py-1 rounded-md mr-2"
                                                        >
                                                            <BsPencilSquare />
                                                        </button>
                                                        <button
                                                            onClick={() => handleDelete(item.id)}
                                                            className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-md"
                                                        >
                                                            <BsTrash />
                                                        </button>
                                                    </div>
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </ul>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>  */}
            {/* Table for managing topics */}
            <div className="overflow-x-auto">
                <div className="flex">
                    <h2 className="text-xl text-left my-auto font-semibold mb-4">Topics</h2>
                    <div className="mb-2 ml-4">
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md px-3 py-2 mr-2"
                            value={newTopic}
                            onChange={(e) => setNewTopic(e.target.value)}
                            placeholder="Add new topic"
                        />
                        <button onClick={handleAddTopic} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">Add Topic</button>
                    </div>
                </div>
                <table className="table-auto w-full border-collapse border">
                    <thead>
                        <tr>
                            <th className="border px-4 py-2">Name</th>
                            <th className="border px-4 py-2">Edit</th>
                            <th className="border px-4 py-2">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topics.map(topic => (
                            <tr key={topic.id}>
                                <td className="border px-4 py-2">{topic.name}</td>
                                <td className="border px-4 py-2">
                                    <button className="text-blue-500 hover:text-blue-800" onClick={() => handleTopicEdit(topic)}>
                                        <BsPencilSquare />
                                    </button>
                                </td>
                                <td className="border px-4 py-2">
                                    <button className="text-red-500 hover:text-red-800" onClick={() => handleTopicDelete(topic)}>
                                        <BsTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl font-semibold mb-4">{editedData.id ? 'Edit Book' : 'Add Book'}</h2>
            <input 
                type="text" 
                name="name" 
                value={newBookData.name} 
                onChange={handleNewBookChange} 
                className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" 
                placeholder="Name" 
            />
            <div className="mb-4">
                <select 
                    name="topic"
                    value={newBookData.topic} 
                    onChange={handleNewBookChange} 
                    className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
                >
                    <option value="">Select Topic</option>
                    {topics.map(topic => (
                        <option key={topic.id} value={topic.id}>{topic.name}</option>
                    ))}
                </select>
            </div>
            <input 
                type="text" 
                name="link" 
                value={newBookData.link} 
                onChange={handleNewBookChange} 
                className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" 
                placeholder="Link" 
            />
            <button 
                onClick={handleSave} 
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
            >
                Save
            </button>
            <button 
                onClick={() => setIsModalOpen(false)} 
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md ml-2"
            >
                Cancel
            </button>
        </div>
    </div>
)}

{isModaltopicOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                        <h2 className="text-xl font-semibold mb-4">
                            {editedTopic.id ? 'Edit Topic' : 'Add Topic'}
                        </h2>
                        <input
                            type="text"
                            name="name"
                            value={editedTopic.name ?? ''}
                            onChange={(e) =>
                                setEditedTopic({ ...editedTopic, name: e.target.value })
                            }
                            className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full"
                            placeholder="Topic Name"
                        />
                        <button
                            onClick={updateTopic}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                        >
                            Save
                        </button>
                        <button
                            onClick={() => setIsTopicModalOpen(false)}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md ml-2"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
             {isLoading && <LoadingPopup />}
</div>
    );
};

export default Library;
