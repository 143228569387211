import React, { useState, useEffect } from "react";
import { BsPencilSquare, BsTrash, BsPlusCircle } from "react-icons/bs";
import BASE_URL from "../constant";
import axios from "axios";
import LoadingPopup from "../Pages/LoadingPopup";


const EditModal = ({ showModal, setShowModal, selectedItem, handleEdit,setIsLoading,fetchCatalog}) => {
  //const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: selectedItem?.title || "",
    tags: selectedItem?.tags || "",
  });
  const [documentFile, setDocumentFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    if (id === "document") setDocumentFile(files[0]);
    else if (id === "Book_cover") setCoverImage(files[0]);
  };

  const addCatalog = async () => {
    setIsLoading(true);
    try {
      const url = `${BASE_URL}/api_catelog.php`;
      const data = new FormData();

      data.append("title", formData.title);
      //data.append("tags", formData.tags);
      if (documentFile) data.append("file", documentFile);
      if (coverImage) data.append("image", coverImage);

      const response = await axios.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const insertedCatalogItem = response.data;
      console.log(insertedCatalogItem);
      setIsLoading(false);
      setShowModal(false);
      fetchCatalog();
      // Refresh catalog list (or re-fetch if using a list from the server)
      // handleEdit(insertedCatalogItem); // Optionally update your parent list
    } catch (error) {
      setIsLoading(false);
      fetchCatalog();
      console.error("Error adding catalog:", error);
    }
  };

  const handleSubmit = () => {
    if (selectedItem) {
      handleEdit(formData); // Handle edits
    } else {
      addCatalog(); // Handle new addition
    }
  };

  return (
    showModal && (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center h-full justify-center pt-4 px-4 pb-20 text-center">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {selectedItem ? "Edit Item" : "Add New Item"}
              </h3>
              <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="shadow-sm p-2 border border-gray-300 rounded-md w-full"
                  placeholder="Enter title"
                />
                <label className="block text-sm font-medium text-gray-700 mt-3">
                  Tags (comma separated)
                </label>
                <input
                  type="text"
                  name="tags"
                  value={formData.tags}
                  onChange={handleChange}
                  className="shadow-sm p-2 border border-gray-300 rounded-md w-full"
                  placeholder="tag1, tag2"
                />
                <div className="grid grid-cols-2 gap-4 mt-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      File Upload
                    </label>
                    <input
                      type="file"
                      id="document"
                      className="mt-1"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Upload Book Cover
                    </label>
                    <input
                      type="file"
                      id="Book_cover"
                      className="mt-1"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleSubmit}
              >
                Save
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};




const Catalog = () => {
  const [initialData,setIData] = useState([
  ]);
  const [data, setData] = useState(initialData);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchCatalog();
  },[]);

  const  fetchCatalog = async () => {
    try {
 
      const response = await axios.get(`${BASE_URL}/api_catelog.php`);
      const bookData = response.data;
      console.log(response.data);
     setIData(response.data.catalog);
    console.log(initialData);    
    
     
    } catch (error) {

       

     
     
     
      console.error('Error fetching Catalog:', error);
    }
  };

 
  const handleEdit = (item) => {
    if (item.id) {
      // Edit existing item
      setData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.id === item.id ? item : dataItem
        )
      );
    } else {
      // Add new item with unique ID
      setData((prevData) => [
        ...prevData,
        { ...item, id: prevData.length + 1 },
      ]);
    }
  };

  const handleOpenModal = (item = null) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const deleteCatalog=async (id)=>{
    try {
 
      const response = await axios.delete(`${BASE_URL}/api_catelog.php?id=${id}`);
      const bookData = response.data;
      console.log(response.data);
      setIData(initialData.filter((i) => i.id !== id));
      fetchCatalog();
    console.log(initialData);    
    
     
    } catch (error) {

       

     
     
     
      console.error('Error fetching Catalog:', error);
    }
  };

  return (
    <>
      <div className="w-[90%] m-auto mt-10">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Catalog List</h2>
          <button
            className="bg-red-900 text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center gap-2"
            onClick={() => handleOpenModal()}
          >
            <BsPlusCircle /> Add New Catalog
          </button>
        </div>
        <div className="max-h-[450px] overflow-y-auto">
          <table className="w-full">
            <thead className="bg-[#fde5c3c4] text-left">
              <tr>
                <th className="py-4">Title</th>
                {/* <th className="py-4">Tags</th> */}
                <th className="py-4">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white border divide-y divide-gray-200">
              {initialData.map((item) => (
                <tr key={item.id}>
                  <td className="p-2">{item.title}</td>
                  {/* //<td className="p-2">{item.tags}</td> */}
                  <td className="p-2 flex gap-2">
                    {/* <button onClick={() => handleOpenModal(item)}>
                      <BsPencilSquare />
                    </button> */}
                    <button onClick={()=>{
                      deleteCatalog(item.id);
                    }}>
                      <BsTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isLoading && <LoadingPopup />}
      {showModal && (
        <EditModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          handleEdit={handleEdit}
          setIsLoading={setIsLoading}
          fetchCatalog={fetchCatalog}

        />
      )}
    </>
  );
};

export default Catalog;
