import React, { useState, useEffect } from "react";
import axios from 'axios';
import BASE_URL from '../constant';
import htmlToPlainText from "../htmltotext";
// Sample data


const Orders = () => {
  const [sampleOrders,setSampleOrder] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
 
  useEffect(() => {
   
    GetOrder();
  }, []);
  
  const GetOrder = async (orderData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order successfully fetched:', response.data);
      setSampleOrder(response.data.orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  
  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php?id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setSelectedOrder(response.data.order); // Set the specific order details
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };
  const [orders, setOrders] = useState(sampleOrders);
  // const [filteredOrders, setFilteredOrders] = useState(sampleOrders);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = async (order) => {
    // setSelectedOrder(order);
    await fetchOrderDetails(order.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Digital Orders</h1>
      {/* <input
        type="text"
        placeholder="Search by user name or item name"
        className="border rounded p-2 mb-4"
        value={searchTerm}
        onChange={handleSearchChange}
      /> */}
      <table className="min-w-full border border-gray-300">
        <thead className="bg-gray-200">
          <tr className="bg-[#fde5c3c4]">
            <th className="border text-left px-4 py-2">User </th>
            <th className="border text-left px-4 py-2">Purchase Date</th>
            <th className="border text-left px-4 py-2">Item Name</th>
            <th className="border text-left px-4 py-2">Price</th>
            <th className="border text-left px-4 py-2">View Details</th>
          </tr>
        </thead>
        <tbody>
          {sampleOrders.map(order => (
            <tr key={order.id} className="hover:bg-gray-100 ">
              <td className="border text-left px-4 py-2">{order.username}</td>
              <td className="border text-left px-4 py-2">{order.purchaseDate}</td>
              <td className="border text-left px-4 py-2">{order.details[0].message}</td>
              <td className="border text-left px-4 py-2">${order.total_price}</td>
              <td className="border text-left px-4 py-2">
                <button
                  className="bg-[#6D3815] w-full text-white rounded px-2 py-1"
                  onClick={() => handleRowClick(order)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[80%]">
            <h2 className="text-xl font-bold mb-4">Edit Order</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block mb-2">Order ID</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  DIGI-ORD-{selectedOrder.id}
                </div>
              </div>
              <div>
                <label className="block mb-2">Customer Name</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.username}
                </div>
              </div>
              <div>
                <label className="block mb-2">Transaction ID</label>
                <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
                  {selectedOrder.transaction_id}
                </div>
              </div>

              {/* <div>
                <label className="block mb-2 px-2">Status</label>
                <select
                  value={delivery_status_enum(deliveryStatus)}
                  onChange={handleStatusChange}
                  className="border-b-2 bg-gray-300 border-gray-300 rounded p-2 w-full"
                >
                  {deliveryStatuses.map((status) => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
              </div> */}
              <div className="col-span-3 overflow-auto max-h-[300px]">
                <label className="block mb-2">Subscription Purchased</label>
                <div className="border-b-2 border-gray-300 p-2">
                  {selectedOrder.details?.map((item) => (
                    <div className="flex justify-between py-2 border-b-2 border-gray-100 align-center" key={item.id}>
                      <span className="text-gray-500">{htmlToPlainText(item.title)}</span>
                      <span className="text-gray-500">${item.price}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 mt-6 gap-4">
             
            
             
              <div>
                <label className="block mb-2">Payment Method</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.paymentMethod ?? "Card Payment"}
                </div>
              </div>
              <div>
                <label className="block mb-2">Order Date</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.purchaseDate}
                </div>
              </div>
              <div>
                <label className="block mb-2">Total</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  ${selectedOrder.total_price}
                </div>
              </div>
            </div>
           
            <button onClick={closeModal} className="mt-4 ml-2 bg-red-500 text-white p-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
