import React, { useState,useEffect } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import { RiImageAddLine } from 'react-icons/ri'; // Import the icon component
import BASE_URL from "../constant";
import axios from "axios";
import LoadingPopup from "../Pages/LoadingPopup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Markup } from "interweave";
import Select from "react-select";
import NODE_BASE_URL from "../node_constant";

const AddBook = () => {
  const [type,setType]=useState(localStorage.getItem("type") || '');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [newBooks, setNewBooks] = useState(true);
  const [imagePreviews, setImagePreviews] = useState(Array(6).fill(null));
  const [bookList, setBookList] = useState([ ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [auther, setAuther] = useState('');
  const [selectedfiles, setSelectedFiles] = useState('');
  const [content, setContent] = useState('');
  const [data, setData] = useState([]);

  const handletitleChange = (value) => {
    setTitle(value);
  };
  const handleautherChange = (value) => {
    setAuther(value);
  };
  const handleChange = (value) => {
    setContent(value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectedFile(file);
    if (selectedFile) {
      // You can access the file path using selectedFile.name or handle its data
      console.log("Selected File:", selectedFile);
      // Example: Save the file path to state or perform further actions
      // setState({ filePath: selectedFile.name });
    } else {
      console.log("No file selected");
    }
  };
  useEffect(() => {
  
    fetchBooks();
    fetchCategories();
    fetchPdfs();
  }, []);
const fetchPdfs = async () => {
    try {
      let url = `${BASE_URL}/add_files.php?type=searchable`;
      const response = await axios.get(url);
      const bookData = response.data.results;
      console.log(response.data.files);
      console.log(response.data.files);

      if (Array.isArray(response.data.files)) {
          // Create options for the dropdown
          const fileOptions = response.data.files.map((file, index) => ({
              value: index, // or any other unique identifier
              label: file,
          }));
      
          // Assuming you have a state setter for these options
          setData(fileOptions); // Replace with the appropriate state setter
      
     
      } else {
        console.error("Expected an array of pdfs");
      }
    } catch (error) {
      console.error("Error fetching pdfs:", error);
    }
  };
  const fetchBooks = async () => {
      try {
          let url = `${BASE_URL}/api_book.php/books`;
          
          const response = await axios.get(url);
          const bookData = response.data;
          console.log(response.data);
          if (Array.isArray(bookData)) {
            setBookList(bookData);
              // sliderData1(bookData);
              // extractTopics(bookData);
          } else {
              console.error('Expected an array of books');
          }
      } catch (error) {
          console.error('Error fetching books:', error);
      }
  };
  const fetchCategories = async () => {
    try {
        let url = `${BASE_URL}/api_categories.php`;
       
        const response = await axios.get(url);
        const bookData = response.data;
        console.log(response.data);
        if (Array.isArray(bookData)) {
          setCategories(bookData);
           
        } else {
            console.error('Expected an array of Categories');
        }
    } catch (error) {
        console.error('Error fetching books:', error);
    }
};
  const openModal = (book) => {
    console.log(book);
    setSelectedBook(book);
    if (book?.images?.length > 0) {
      setImagePreviews(book.images);
    }
   
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBook(null);
    
    setIsModalOpen(false);
  };

  const handleInsert = async (newBook) => {
    setIsLoading(true);
    try {
      const url = `${BASE_URL}/api_book.php/books`;
      const formData = new FormData();
  
      // Append book details
      formData.append('title', newBook.title);
      formData.append('auther', newBook.auther);
      formData.append('category', newBook.category);
      formData.append('asin', newBook.asin);
      formData.append('size', newBook.size);
      formData.append('material', newBook.material);
      formData.append('volumes', 1);
      formData.append('rental_prices', 0);
      formData.append('pdf_location', selectedfiles);
      formData.append('price', newBook.price);
      formData.append('price_is', newBook.price_is);
      formData.append('description', newBook.description);
      formData.append('isfree', newBook.isFree ? 1 : 0);
      formData.append('read_limit', newBook.read_limit);
      formData.append('warehouse_quantity',newBook.warehouse_quantity);
      formData.append('warehouse_quantity_is',newBook.warehouse_quantity_is);
  
      // Handle images
      await processImages(formData);
  
      const response = await axios.post(url, formData, { headers: { 'content-type': 'multipart/form-data' } });
      const insertedBook = response.data;
  //console.log(insertedBook.book);
     
      setBookList([...bookList, insertedBook.book]);
      closeModal();
      fetchBooks();
      setImagePreviews(Array(6).fill(null));
      setNewBooks(true);
      // setIsLoading(false);
      console.log(insertedBook.book.pdf_location);
      if(insertedBook.book.pdf_location!=''){

     await indexBook(insertedBook.book);
     }
    } catch (error) {
      console.error('Error inserting book:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  async function processImages(formData) {
    imagePreviews.forEach((dataUrl, index) => {
      if (dataUrl != null) {
        const filename = generateFilename(index);
        const imageFile = dataURLtoFile(dataUrl, filename);
        formData.append('images[]', imageFile);
      }
    });
  }
  
  function generateFilename(index) {
    const now = new Date();
    const timestamp = now.toISOString().replace(/:/g, '-').replace(/\..+/, '');
    return `Books_image${index}_${timestamp}.png`;
  }
  
  async function indexBook(book) {
    const insertUrl = `${NODE_BASE_URL}/index`;
    const updatedPdfLocation = book.pdf_location.includes("uploads/searchable/") 
  ?book.pdf_location 
  : `uploads/searchable/${book.pdf_location}`;

    const insertResponse = await axios.post(insertUrl, { pdfFiles: [{ ...book,pdf_location:updatedPdfLocation }] });
    console.log(insertResponse);

  }
  


  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      
            const url = `${BASE_URL}/api_book.php/books/${id}`;
      const response =  await axios.delete(url);
console.log(response);
const url1 = `${NODE_BASE_URL}/index/${id}`;
setIsLoading(false);
fetchBooks();
      const response1 =  await axios.delete(url1);
      console.log(response1);
    setBookList(bookList.filter(book => book.id !== id));
    //  fetchBooks();
    } catch (error) {
      console.log('Error deleting book:', error);
    }
  };
  const handleEdit = async (id, updatedBook) => {
    setIsLoading(true);
    try {
      const url = `${BASE_URL}/api_book.php/books/?id=${id}&_method=PATCH`;
      const formData = new FormData();
     if(type==='israel-warehouse') {
      formData.append('price_is', updatedBook.price_is);
      formData.append('warehouse_quantity_is',updatedBook.warehouse_quantity_is);
     }else{
      formData.append('price', updatedBook.price);
      
      formData.append('warehouse_quantity',updatedBook.warehouse_quantity);
     }
      
     
   

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const response = await axios.post(url,  formData, config);
      const updatedData = response.data;
      setBookList(bookList.map(book => (book.id === id ? updatedData : book)));
      closeModal();
      
const del_url = `${NODE_BASE_URL}/index/${id}`;
const del_response =  await axios.delete(del_url);
console.log(del_response);
      fetchBooks();
      setImagePreviews(Array(6).fill(null));
      setIsLoading(false);
      console.log(selectedfiles);
      if(selectedfiles!=''){
      await indexBook(
  {...updatedBook,id:id} 
)}


//console.log(insert_response);

      setNewBooks(true);
    } catch (error) {
      console.error('Error updating book:', error);
      setIsLoading(false);
    }
  };

  // const handleEdit = (id, updatedBook) => {
  //   setBookList(bookList.map(book => (book.id === id ? updatedBook : book)));
  //   closeModal();
  // };

  // const handleDelete = (id) => {
  //   setBookList(bookList.filter(book => book.id !== id));
  // };

  const handlefileChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedFiles(selectedOption ? selectedOption.label : null);
};
  const [categories,setCategories]=useState([]); 

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newPreviews = [...imagePreviews];
        newPreviews[index] = reader.result;
        setImagePreviews(newPreviews);
      };
      reader.readAsDataURL(file);
    }
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageDChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  return (
    <>
      <div className="container mx-auto py-8">
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">Book List</h2>
          {/* <button
             onClick={() =>{
              setNewBooks(true);
              setSelectedFiles('');
              setAuther('');
              setTitle('');
              setContent('');
              setImagePreviews(Array(6).fill(null));
               openModal({ id:0,
                title: "",
                auther: "",
               // category: e.target.category.value,
                size:"",
                asin:"",
                material:"",
                description: "",
                });}}
            className="bg-[#6D3815] drop-shadow-xl hover:bg-black text-white px-4 py-2 rounded-md flex items-center focus:outline-none"
          >
            <FaPlus className="mr-2" />
            Add New Book
          </button> */}
        </div>
        <div className="overflow-y-auto max-h-[600px]">
          <table className="table-auto  w-full rounded-md border-collapse border border-gray-400">
            <thead className="bg-[#fde5c3c4]  text-left">
              <tr>
                <th className="px-4 py-2  border border-gray-400">
                  Name
                </th>
                <th className="px-4 py-2 border border-gray-400">
                  Author
                </th>
                <th className="px-4 py-2 border border-gray-400">
                 Price
                </th>
                <th className="px-4 py-2 border border-gray-400">
                Quantity
                </th>
                <th className="px-4 py-2 border border-gray-400">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {bookList.map(book => (
                <tr key={book.id}>
                  <td className="px-4 py-2 border border-gray-400">
                  <Markup content={book.title}  /> </td>
                  {/* <td className="px-4 py-2 border border-gray-400">{Markup content={book.title}}</td> */}
                  {/* <td className="px-4 py-2 border border-gray-400">{book.auther}</td> */}
                  <td className="px-4 py-2 border border-gray-400">
                  <Markup content={book.auther}  /> </td>
                  <td className="px-4 py-2 border border-gray-400">{type!=="israel-warehouse"? book.price:book.price_is}</td>
                  <td className="px-4 py-2 border border-gray-400">{type!=="israel-warehouse"?book.warehouse_quantity:book.warehouse_quantity_is}</td>
                  <td className="px-4 py-2 border border-gray-400">
                    <button 
                      onClick={() =>{
                        setNewBooks(false);
                        setAuther(book.auther);
                        setTitle(book.title);
//                         const url = new URL(book.pdf_location);
// const filename = url.pathname.split('/').pop();
// console.log(filename);
                        setSelectedFiles(book.pdf_location);
                        setContent(book.description);
                         openModal(book);}}
                      className="mr-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                    >
                      <FaEdit />
                    </button>
                    {/* <button 
                      onClick={() => handleDelete(book.id)}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      <FaTrash />
                   </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 w-full flex justify-center items-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white w-[80%] h-[40%] overflow-auto	 p-8 rounded-lg z-50">
            {/* Your edit form goes here */}
            <h2 className="text-xl font-semibold mb-4">{newBooks==false ? "Edit Book" : "Add Book"}</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              console.log("submit");
               if (newBooks==false) {
                handleEdit(selectedBook.id, {
                  ...selectedBook,
                 
                  warehouse_quantity:type==='israel-warehouse'?" " :e.target.warehouse_quantity.value,
                  warehouse_quantity_is:type==='israel-warehouse'?e.target.warehouse_quantity_is.value:" ",
                  
                  price:type==='israel-warehouse'?" ": e.target.priceForPurchase.value,
                  price_is:type==='israel-warehouse'? e.target.priceForPurchaseIs.value:" ",
              
             
                });
                } 
              }}
  
  
             
            >
              <div className="grid grid-cols-1 gap-4">
{type!=='israel-warehouse'&&(<> <div className="mb-4 form-in">
    <label htmlFor="warehouse_quantity" className="block text-sm font-medium text-gray-700">Choose Qunatity USA Warehouse</label>
    <input type="number" id="warehouse_quantity" placeholder=" Qunatity" name="warehouse_quantity" defaultValue={selectedBook.warehouse_quantity} className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
  </div> <div className="mb-4 form-in">
    <label htmlFor="priceForPurchase" className="block text-sm font-medium text-gray-700">Price for Purchase In USA</label>
    <input type="number" id="priceForPurchase" placeholder="Price for Purchase USA" name="priceForPurchase" defaultValue={selectedBook.price} className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
  </div></>)}
  {type==='israel-warehouse'&&(<> <div className="mb-4 form-in">
    <label htmlFor="warehouse_quantity" className="block text-sm font-medium text-gray-700">Choose Qunatity Israel Warehouse</label>
    <input type="number" id="warehouse_quantity_is" placeholder=" Qunatity" name="warehouse_quantity_is" defaultValue={selectedBook.warehouse_quantity_is} className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
  </div> 
  
  <div className="mb-4 form-in">
    <label htmlFor="priceForPurchase" className="block text-sm font-medium text-gray-700">Price for Purchase In Israel</label>
    <input type="number" id="priceForPurchaseIs" placeholder="Price for Purchase Israel" name="priceForPurchaseIs" defaultValue={selectedBook.price_is} className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
  </div></>)}
 
 
</div>
 



              <div className="flex justify-end">
                <button type="button" onClick={closeModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
                >
                  {selectedBook.id ? "Save Changes" : "Add Book"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isLoading && <LoadingPopup />}
    </>
  );
};
function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const blob = new Blob([u8arr], { type: mime });
  const file = new File([blob], filename, { type: mime });
  
  return file;
}

export default AddBook;
