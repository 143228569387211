import React, { useState ,useEffect} from "react";
import Select from "react-select";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "../Pages/LoadingPopup";
import htmlToPlainText from "../htmltotext";

const AddSubscription = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [books, setBookList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [newSubscription, setNewSubscription] = useState({
    category: [],
    numberOfBooks: "",
    price: "",
    title:"",
    books: [],
  });

  useEffect(() => {
    // fetchBooks();
    fetchCategories();
    fetchSubscriptions();
  }, []);
  useEffect(() => {
    if (newSubscription.category.length > 0) {
      fetchBooks(newSubscription.category.map(cat => cat.label));
    } else {
      setBookList([]);
      // fetchBooks();
    }
  }, [newSubscription.category]);

  const fetchBooks = async (categoryIds = []) => {
    try {
      let url = `${BASE_URL}/api_book.php/books`;
      if (categoryIds.length > 0) {
        url += `?categories=${categoryIds.join(",")}`;
      }
      const response = await axios.get(url);
      const bookData = response.data;
      console.log(response.data);
      if (Array.isArray(bookData)) {
        const bookOptions = bookData.map(book => ({
          value: book.id,
          label: htmlToPlainText(book.title),
        }));
        setBookList([{ value: 'all', label: 'All Books' }, ...bookOptions]);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };
  // const fetchBooks = async () => {
  //   try {
  //     const url = `${BASE_URL}/api_book.php/books`;
  //     const response = await axios.get(url);
  //     const bookData = response.data;
  //     console.log(response.data);
  //     if (Array.isArray(bookData)) {
  //       const bookOptions = bookData.map(book => ({
  //         value: book.id,
  //         label: book.title,
  //       }));
  //       setBookList(bookOptions);
  //     } else {
  //       console.error("Expected an array of books");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching books:", error);
  //   }
  // };

  const fetchCategories = async () => {
    try {
      const url = `${BASE_URL}/api_categories.php`;
      const response = await axios.get(url);
      const categoryData = response.data;
      console.log(response.data);
      if (Array.isArray(categoryData)) {
        const categoryOptions = categoryData.map(category => ({
          value: category.id,
          label: category.name,
        }));
        setCategories(categoryOptions);
      } else {
        console.error("Expected an array of categories");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_subscription.php`);
      console.log(response.data);
      setSubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setNewSubscription({ category: [], numberOfBooks: "", price: "", books: [] });
  };
  const handletitleChange = (e) => {
    const { name, value } = e.target;
    setNewSubscription((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubscription((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCategoryChange = (selectedOptions) => {
    setNewSubscription((prevState) => ({
      ...prevState,
      category: selectedOptions,
    }));
  };

  const handleBookChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      setNewSubscription((prevState) => ({
        ...prevState,
        books: books.filter(book => book.value !== 'all'),
        numberOfBooks: books.length - 1, // Exclude 'All Books' from count
      }));
    } else {
      setNewSubscription((prevState) => ({
        ...prevState,
        books: selectedOptions,
        numberOfBooks: selectedOptions.length,
      }));
    }
  };
  const handleAddSubscription = async () => {
    if (newSubscription.category.length && newSubscription.numberOfBooks && newSubscription.price) {
      if (newSubscription.id) {
        console.log(newSubscription);
        console.log(newSubscription);
        const bookIds = newSubscription.books.map(book => book.value);
        const cateIds = newSubscription.category.map(book => book.value);
        // If id exists, it means we are editing an existing subscription
        handleEdit(newSubscription.id,  {...newSubscription,category_id:cateIds,books_id:bookIds,duration:30});
      } else {
        // Otherwise, add a new subscription
        try {
          setIsLoading(true);
          console.log(newSubscription);
          const bookIds = newSubscription.books.map(book => book.value);
          const cateIds = newSubscription.category.map(book => book.value);
       
        
          const response = await axios.post(`${BASE_URL}/api_subscription.php`, {...newSubscription,category_id:cateIds,books_id:bookIds,duration:30});
          console.log(response.data);
          setIsLoading(false);
        //  fetchSubscriptions();
       //  setSubscriptions([...subscriptions, { ...newSubscription, id: response.data.id,category:cateoptions, }]);
         fetchSubscriptions();
        } catch (error) {
          setIsLoading(false);
          console.error("Error adding subscription:", error);
        }
      }
      handleModalClose();
    }
  };

  const handleEdit = async (id, updatedSubscription) => {
    try {
      setIsLoading(true);
      await axios.put(`${BASE_URL}/api_subscription.php?id=${id}`, updatedSubscription);
      const updatedSubscriptions = subscriptions.map((sub) =>
        sub.id === id ? updatedSubscription : sub
      );
      setSubscriptions(updatedSubscriptions);
      setIsLoading(false);
      fetchSubscriptions();
    } catch (error) {
      setIsLoading(false);
      console.error("Error editing subscription:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api_subscription.php?id=${id}`);
      const updatedSubscriptions = subscriptions.filter((sub) => sub.id !== id);
      setSubscriptions(updatedSubscriptions);
    } catch (error) {
      console.error("Error deleting subscription:", error);
    }
  };

  const handleEditButtonClick = (subscription) => {
    console.log(subscription);
    const bookOptions = subscription.books.map(book => ({
      value: book.id,
      label: book.title,
    }));
    const cateOptions = subscription.category.map(book => ({
      value: book.id,
      label: book.name,
    }));
    setNewSubscription({...subscription,books:bookOptions,category:cateOptions,numberOfBooks: subscription.books.length,});
    setModalOpen(true);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Subscriptions</h2>
        <button
          onClick={handleModalOpen}
          className="bg-[#6D3815] hover:bg-black text-white py-2 px-4 rounded"
        >
          Add Subscription
        </button>
      </div>
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-[#fde5c3c4] text-left">
          <th className="px-4 py-2">Subscription Title</th>
            <th className="px-4 py-2">Category Name</th>
            <th className="px-4 py-2">No of Books</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr key={subscription.id}>
               <td className="border px-4 py-2">{subscription.title}</td>
              <td className="border px-4 py-2">
                {subscription.category.map((cat) => cat.name).join(", ")}
              </td>
              <td className="border px-4 py-2">{subscription.books.length}</td>
              <td className="border px-4 py-2">{subscription.price}</td>
              <td className="border px-4 py-2">
                <button
                  onClick={() => handleEditButtonClick(subscription)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-1"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => handleDelete(subscription.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white w-[70%] p-8 rounded shadow-lg">
            <h3 className="text-lg font-bold mb-4">
              {newSubscription.id ? "Edit Subscription" : "Add Subscription"}
            </h3>
            <div className="mb-4"> <div className="mb-4">
              <label className="block mb-2" htmlFor="title">
                Title:
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={newSubscription.title}
                onChange={handletitleChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
              <label className="block mb-2" htmlFor="category">
                Category:
              </label>
              <Select
                id="category"
                name="category"
                isMulti
                options={categories}
                value={newSubscription.category}
                onChange={handleCategoryChange}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="numberOfBooks">
                No of Books:
              </label>
              <input
                type="number"
                id="numberOfBooks"
                name="numberOfBooks"
                value={newSubscription.numberOfBooks}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                disabled
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="price">
                Price:
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={newSubscription.price}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="books">
                Books:
              </label>
              <Select
                id="books"
                name="books"
                isMulti
                options={books}
                value={newSubscription.books}
                onChange={handleBookChange}
                className="w-full"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleAddSubscription}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                {newSubscription.id ? "Save" : "Add"}
              </button>
              <button
                onClick={handleModalClose}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
       {isLoading && <LoadingPopup />}
    </div>
  );
};

export default AddSubscription;
