
// import React from "react";
import React, { useState, useEffect, useContext} from 'react';

import Admin from './Admin/Admin';
 import '../src/Login.css'; 
import { get, ref } from 'firebase/database';
import { auth, db } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import AdminSub from './Admin-sub/Admin-sub';




function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [type,setType]=useState(localStorage.getItem('type')||'');
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    const lastLoginTimestamp = localStorage.getItem('lastLoginTimestamp');
    
    // Check if user was logged in within the last 5 minutes
    if (storedIsLoggedIn === 'true' && lastLoginTimestamp) {
      const currentTime = new Date().getTime();
      const sessionTimeout = 5 * 60 * 1000; // 5 minutes in milliseconds
      
      if (currentTime - parseInt(lastLoginTimestamp) < sessionTimeout) {
        setLoggedIn(true);
      } else {
        // Session expired, log out the user
        setLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('lastLoginTimestamp');
        localStorage.removeItem('type');
      }
    } else {
      // No valid login session, log out the user
      setLoggedIn(false);
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('lastLoginTimestamp');
      localStorage.removeItem('type');
    }
  }, []);
   

  
  //Function to handle login
  const handleLogin = async () => {
    try {
      // if (username === 'admin@gmail.com'&& password=='admin1234') {
        const userCredential = await signInWithEmailAndPassword(auth, username, password);
        const user = userCredential.user;
       
        const userRef = ref(db, `users/${user.uid}`);

// try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
        const userData = snapshot.val();
        const name = userData.name;
       setType(userData.type);

        console.log("User Name:", name);
        console.log("User Type:", userData.type);
        const loginTimestamp = new Date().getTime(); // Get current timestamp
        localStorage.setItem('lastLoginTimestamp', loginTimestamp);
        // Save isLoggedIn to local storage
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('type',userData.type);
        console.log(new Date(loginTimestamp).toLocaleString()); // Log the login date and time
        alert('Login successful!');
        setLoggedIn(true);
    } else {
        console.log("No data available");
    }
// } catch (error) {
//     console.error("Error fetching user data:", error);
// }
      // }
      //   
      // } else {
      //   setError('Kindly Enter Admin Id ');
      // }
    } catch (error) {
      setError('Error Occurred. Please try again later.');
    }
  };
  
  // const handleLogin = () => {
  //   // Simple login logic (replace it with your authentication logic)
  //   if (username === 'demo' && password === 'password') {
  //     // Successful login
  //     const loginTimestamp = new Date().getTime(); // Get current timestamp
  //     localStorage.setItem('lastLoginTimestamp', loginTimestamp);
  //     // Save isLoggedIn to local storage
  //     localStorage.setItem('isLoggedIn', true);
  //     console.log(new Date(loginTimestamp).toLocaleString()); // Log the login date and time
  //     alert('Login successful!');
  //     setLoggedIn(true);
  //   } else {
  //     setError('Invalid username or password');
  //   }
  // };
  // Function to handle logout
  const handleLogout = () => {
    setLoggedIn(false);
    // Remove isLoggedIn from local storage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('lastLoginTimestamp');
    localStorage.removeItem('type');
  };

 
  return (
    <>
      {!isLoggedIn ? (
       <div className="custom-login-container">
       <div className="custom-login-form">
         {/* Uncommented the logo with specific height */}
         <img
           src="https://oizvehadar.com/api/uploads/images/logo.png"
           className="h-[150px] mx-auto"
           alt="logo"
         />
     
         <h2 className="mt-5 mb-5 text-center">Admin Login</h2>
         
         {error && <div className="custom-error-message">{error}</div>}
         
         <div className="custom-form-group">
           <label htmlFor="username">Email:</label>
           <input
             className="custom-input"
             type="text"
             id="username"
             value={username}
             onChange={(e) => setUsername(e.target.value)}
           />
         </div>
         
         <div className="custom-form-group mt-5 mb-5">
           <label htmlFor="password">Password:</label>
           <input
             className="custom-input"
             type="password"
             id="password"
             value={password}
             onChange={(e) => setPassword(e.target.value)}
           />
         </div>
     
         {/* Centered Login Button */}
         <div className="custom-form-group flex justify-center">
           <button className="custom-button" onClick={handleLogin}>Login</button>
         </div>
       </div>
     </div>
     
      ) : (
        type==="super-admin"? (<Admin isLoggedIn={isLoggedIn} handleLogout={handleLogout} />):(<AdminSub isLoggedIn={isLoggedIn} handleLogout={handleLogout} />)
       
       
        
      )}
      {/* <Footer/> */}
    </>
  );
}

export default App;