

import React, { useState, useEffect } from "react";
import axios from 'axios';
import BASE_URL from '../constant';
import htmlToPlainText from "../htmltotext";

const ActiveOrder = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [trackingId, setTrackingId] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState('');
const delivery_status_enum = (stat) => {
  switch (stat.toLowerCase()) {
    case 'pending':
      return 'Booked';
    case 'shipped':
      return 'Dispatched';
    case 'delivered':
      return 'Delivered';
    case 'cancelled':
      return 'Cancel';
    default:
      return 'Booked';
  }
};

// Admin statuses back to database statuses
const admin_status_enum = (adminStat) => {
  switch (adminStat.toLowerCase()) {
    case 'booked':
      return 'pending';
    case 'dispatched':
      return 'shipped';
    case 'delivered':
      return 'delivered';
    case 'cancel':
      return 'cancelled';
    default:
      return 'pending'; // Default case
  }
};
  const deliveryStatuses = ['Booked', 'Dispatched', 'Delivered', 'Cancel'];

  const handleEdit = async (order) => {
    await fetchOrderDetails(order.id);
    setModalOpen(true);
  };

  useEffect(() => {
    GetOrder();
  }, []);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_porders.php?action=get_order_details&order_id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setSelectedOrder({ ...response.data.order, id: orderId });
      setDeliveryStatus(response.data.order.delivery_status);
      setTrackingId(response.data.order.tracking_id);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const GetOrder = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_porders.php?action=get_all_orders`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Orders successfully fetched:', response.data);
      setOrders(response.data.orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleStatusChange = (e) => {
    setDeliveryStatus(e.target.value);
  };

  const handleTrackingIdChange = (e) => {
    setTrackingId(e.target.value);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setTrackingId(''); // Resetting state when modal closes
    setDeliveryStatus(''); // Resetting state when modal closes
  };

  const handleSaveTrackingId = async () => {
    try {
      const updatedOrder = {
        order_id: selectedOrder.id,
        tracking_id: trackingId,
        delivery_status: admin_status_enum(deliveryStatus)
      };
      console.log(updatedOrder);

      // const promises = [];

      if (selectedOrder.tracking_id !== trackingId) {
       
        const response_tracking  =      axios.put(`${BASE_URL}/api_porders.php?action=update_tracking_status`, {
            order_id: updatedOrder.order_id,
            tracking_id: updatedOrder.tracking_id
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
       console.log(response_tracking.data);
      }

      if (selectedOrder.delivery_status !== deliveryStatus) {
       
 const response_status   =   await       axios.put(`${BASE_URL}/api_porders.php?action=update_tracking_status`, {
            order_id: updatedOrder.order_id,
            delivery_status: updatedOrder.delivery_status
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
           console.log(response_status.data);
       
      }

      // await Promise.all(promises);
      console.log('Updates successful');

      const updatedOrders = orders.map(order =>
        order.id === updatedOrder.order_id
          ? { ...order, tracking_id: updatedOrder.tracking_id, delivery_status: updatedOrder.delivery_status }
          : order
      );

      setOrders(updatedOrders);
      setModalOpen(false); // Close modal after saving
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Orders</h1>
      <table className="border-collapse w-full border border-gray-200 mt-4">
        <thead className="bg-[#fde5c3c4] text-left">
          <tr>
            <th className="border border-gray-300 p-2">Order ID</th>
            <th className="border border-gray-300 p-2">Customer Name</th>
            <th className="border border-gray-300 p-2">Currency</th>
            <th className="border border-gray-300 p-2">Total Price</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Tracking ID</th>
            <th className="border border-gray-300 p-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td className="border border-gray-300 p-2">PHY-ORD-{order.id}</td>
              <td className="border border-gray-300 p-2">{order.username}</td>
              <td className="border border-gray-300 p-2">{order.currency.toUpperCase()}</td>
              <td className="border border-gray-300 p-2">{order.currency!=="ils" ? "$" : "₪" } {order.final_amount}</td>
              <td className="border border-gray-300 p-2">{delivery_status_enum(order.delivery_status)}</td>
              <td className="border border-gray-300 p-2">{order.tracking_id}</td>
              <td className="border border-gray-300 p-2">
                <button
                  onClick={() => handleEdit(order)}
                  className="bg-[#6D3815] w-full text-white rounded px-2 py-1"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[80%]">
            <h2 className="text-xl font-bold mb-4">Edit Order</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block mb-2">Order ID</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  PHY-ORD-{selectedOrder.id}
                </div>
              </div>
              <div>
                <label className="block mb-2">Customer Name</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.username}
                </div>
              </div>

              <div>
                <label className="block mb-2 px-2">Status</label>
                <select
                  value={delivery_status_enum(deliveryStatus)}
                  onChange={handleStatusChange}
                  className="border-b-2 bg-gray-300 border-gray-300 rounded p-2 w-full"
                >
                  {deliveryStatuses.map((status) => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
              </div>
              <div className="col-span-3 overflow-auto max-h-[300px]">
              <div className="col-span-3 overflow-auto max-h-[300px]">
    <div className="border-b-2 border-gray-300 p-2">
        <div className="flex justify-between py-2 border-b-2 border-gray-100 items-center">
            <span className="text-gray-500 text-center flex-1">Items</span>
            <span className="text-gray-500 text-center flex-1">Price</span>
            <span className="text-gray-500 text-center flex-1">QTY</span>
            <span className="text-gray-500 text-center flex-1">Total Price</span>
        </div>
        {selectedOrder.details?.map((item) => (
            <div 
                className="flex justify-between py-2 border-b-2 border-gray-100 items-center" 
                key={item.id}
            >
                <span className="text-gray-500 text-center flex-1">{htmlToPlainText(item.title)}</span>
                <span className="text-gray-500 text-center flex-1">
                    {selectedOrder.currency !== "ils" ? "$" : "₪"} {item.price}
                </span>
                <span className="text-gray-500 text-center flex-1">{item.quantity}</span>
                <span className="text-gray-500 text-center flex-1">
                    {selectedOrder.currency !== "ils" ? "$" : "₪"} {(item.price * item.quantity).toFixed(2)}
                </span>
            </div>
        ))}
    </div>
</div>

</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 mt-6 gap-4">
              <div>
                <label className="block mb-2">Tracking ID</label>
                <input
                  type="text"
                  value={trackingId}
                  onChange={handleTrackingIdChange}
                  className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2 w-full"
                />
              </div>
              <div>
                <label className="block mb-2">Transaction ID</label>
                <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
                  {selectedOrder.transaction_id}
                </div>
              </div>
              <div>
                <label className="block mb-2">Delivery Address</label>
                <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
                  {selectedOrder.address}
                </div>
              </div>
              <div>
                <label className="block mb-2">Billing Address</label>
                <div className="border-b-2 text-gray-500 border-gray-300 p-2">
                  {selectedOrder.address}
                </div>
              </div>
              
              <div>
                <label className="block mb-2">Currency</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.currency.toUpperCase() }
                </div>
              </div>
              <div>
                <label className="block mb-2">Payment Method</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.paymentMethod ?? "Card Payment"}
                </div>
              </div>
              <div>
                <label className="block mb-2">Order Date</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.orderDate}
                </div>
              </div>
              <div>
                <label className="block mb-2">Total</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.currency!=="ils" ? "$" : "₪"} {selectedOrder.final_amount}
                </div>
              </div>
            </div>
            <button onClick={handleSaveTrackingId} className="mt-4 bg-blue-500 text-white p-2 rounded">
              Save 
            </button> 
            <button onClick={closeModal} className="mt-4 ml-2 bg-red-500 text-white p-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveOrder;

