import React, { useState,useEffect } from "react";
import BASE_URL from "../constant";
import axios from "axios";
import htmlToPlainText from "../htmltotext";
import LoadingPopup from "../Pages/LoadingPopup";
const AddIndex = () => {
 
  const [indexes, setIndexes] = useState([]);
  const [books, setBooks] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [indexValue, setIndexValue] = useState("");
  const [pageNoValue, setPageNoValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Function to handle search by book name
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const results = indexes.filter(book =>
      book.title.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setSearchResults(results);
  };
  useEffect(() => {
   fetchIndexes();
    fetchBooks();
}, []);

const fetchBooks = async () => {
    try {
        let url = `${BASE_URL}/api_book.php/books`;
        const response = await axios.get(url);
        const bookData = response.data;
        console.log(response.data);
        if (Array.isArray(bookData)) {
           
            setBooks(bookData);
        } else {
            console.error("Expected an array of books");
        }
    } catch (error) {
        console.error("Error fetching books:", error);
    }
};

const fetchIndexes = async () => {
    try {
        let url = `${BASE_URL}/api_books_indexes.php`;
        const response = await axios.get(url);
        const volumeData = response.data;
        console.log(response.data);
        if (Array.isArray(volumeData)) {
          setIndexes(volumeData);
        } else {
            console.error("Expected an array of volumes");
        }
    } catch (error) {
        console.error("Error fetching volumes:", error);
    }
};
  // Function to handle search by index
  const handleIndexSearch = (event) => {
    const searchIndex = event.target.value;
    if (searchIndex) {
        const results = indexes.filter(book => book.index.toLowerCase().includes(searchIndex.toLowerCase()));
        setSearchResults(results);
    } else {
        setSearchResults(indexes);
    }
};


  const handleEdit = (index) => {
    setEditIndex(index);
    setSelectedIndex(index.book_id);
    setIndexValue(index.index);
    setPageNoValue(index.pageNum);
    setShowModal(true);
  };

  const handleSave = async () => {
    if (editIndex !== null) {
      const updatedBooks = [...books];
      updatedBooks[editIndex] = {
        id: editIndex.id,
        book_id: selectedIndex,
        index: indexValue,
        pageNum: pageNoValue
      };
      try {
        setIsLoading(true);
        const response = await axios.put(`${BASE_URL}/api_books_indexes.php?id=${editIndex.id}`, {
          book_id: selectedIndex,
          index: indexValue,
          pageNum: pageNoValue
        });
        if (response.status === 200) {
           
            setBooks(updatedBooks);
            setEditIndex(null);
            fetchIndexes();
           setIsLoading(false);
        }
    } catch (error) {
        setIsLoading(false);
        console.error("Error updating volume:", error);
    }
     
    } else {
      // const newId = books.length + 1;
      const newBook = {
        // id: newId,
        book_id:selectedIndex ,
        index: indexValue,
        pageNum: pageNoValue
      };
      try {
        setIsLoading(true);
        const response = await axios.post(`${BASE_URL}/api_books_indexes.php`, newBook);
        if (response.status === 200) {
             setBooks([...books, newBook]);
            fetchIndexes();
           setIsLoading(false);
        }
    } catch (error) {
        setIsLoading(false);
        console.error("Error updating volume:", error);
    }
     
    }
    setShowModal(false);
    setSelectedIndex(0);
    setIndexValue("");
    setPageNoValue("");
    setSearchTerm(""); // Clear search term after save
    setSearchResults([]); // Clear search results after save
  };

  const handleDelete = async (index) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(`${BASE_URL}/api_books_indexes.php?id=${index.id}`);
      if (response.status === 200) {
        const updatedBooks = [...books];
        updatedBooks.splice(index, 1);
        setBooks(updatedBooks);
          //  setBooks([...books, newBook]);
          fetchIndexes();
         setIsLoading(false);
      }
  } catch (error) {
      setIsLoading(false);
      console.error("Error updating volume:", error);
  }
  
  };

  const handleAddIndex = () => {
    setSelectedIndex(0);
    setIndexValue("");
    setPageNoValue("");
    setShowModal(true);
  };
  // Render books based on search results or all data if no search term
  const renderBooks = () => {
    if (searchTerm === "" && searchResults.length === 0) {
      return indexes.map((book, index) => (
        <tr key={index}>
         <td className="px-6 py-4 whitespace-nowrap">{htmlToPlainText(book.title)}</td>
          <td className="px-6 py-4 whitespace-nowrap">{book.index}</td>
          <td className="px-6 py-4 whitespace-nowrap">{book.pageNum}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              onClick={() => handleEdit(book)}
              className="text-indigo-600 hover:text-indigo-900"
            >
              Edit
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              onClick={() => handleDelete(book)}
              className="text-red-600 hover:text-red-900"
            >
              Delete
            </button>
          </td>
        </tr>
      ));
    } else {
      return searchResults.map((book, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">{htmlToPlainText(book.title)}</td>
          <td className="px-6 py-4 whitespace-nowrap">{book.index}</td>
          <td className="px-6 py-4 whitespace-nowrap">{book.pageNum}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              onClick={() => handleEdit(book)}
              className="text-indigo-600 hover:text-indigo-900"
            >
              Edit
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              onClick={() => handleDelete(book)}
              className="text-red-600 hover:text-red-900"
            >
              Delete
            </button>
          </td>
        </tr>
      ));
    }
  };
  return (
    <>
      <div className="container mx-auto py-6">
      <div className="flex w-full">
      <div className="flex w-full justify-start mb-6">
          <input
            type="text"
            placeholder="Search by Book Name"
            value={searchTerm}
            onChange={handleSearch}
            className="border border-gray-300 p-2 rounded-md mr-2"
          />
          <input
            type="text"
            placeholder="Search by Index"
            onChange={handleIndexSearch}
            className="border border-gray-300 p-2 rounded-md"
          />
        </div>
        <div className="flex  w-full justify-end mb-6">
          <button
            onClick={handleAddIndex}
            className="bg-[#6D3815] hover:bg-black text-white font-bold py-2 px-4 rounded"
          >
            Add Index
          </button>
        </div>
        </div>
        <div className="overflow-auto max-h-[400px]">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Book Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Index</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Page No</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Edit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Delete</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderBooks()}
          </tbody>
        </table>
</div>

        {showModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                        {editIndex !== null ? "Edit Index" : "Add Index"}
                      </h3>
                      <div className="mt-2">
                        <form>
                          <div className="mb-4">
                            <label htmlFor="book" className="block text-sm font-medium text-gray-700">
                              Book:
                            </label>
                            <select
                              id="book"
                              name="book"
                              value={selectedIndex}
                              onChange={(e) => setSelectedIndex(parseInt(e.target.value))}
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              {books.map((book) => (
                                <option key={book.id} value={book.id}>
                                  {htmlToPlainText(book.title)}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-4">
                            <label htmlFor="index" className="block text-sm font-medium text-gray-700">
                              Index:
                            </label>
                            <input
                              type="text"
                              id="index"
                              name="index"
                              value={indexValue}
                              onChange={(e) => setIndexValue(e.target.value)}
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="mb-4">
                            <label htmlFor="pageNo" className="block text-sm font-medium text-gray-700">
                              Page No:
                            </label>
                            <input
                              type="number"
                              id="pageNo"
                              name="pageNo"
                              value={pageNoValue}
                              onChange={(e) => setPageNoValue(e.target.value)}
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={handleSave}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#6D3815] text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && <LoadingPopup />}
    </>
  );
};

export default AddIndex;
